window.tryRun("googleAnalytics", function () {
	var retryCounter = 0;
	var init = function() {
		if ((typeof ga === 'function' && ga.loaded) || retryCounter++ > 20) {
			var elements = document.querySelectorAll('[data-ga="track"]');
			for (var i = 0; i < elements.length; i++) {
				switch (elements[i].tagName) {
					case 'VIDEO':
						elements[i].onplay = function () {
							sendGoogleAnalytics(this);
						};
						break;
					case 'FORM':
						elements[i].addEventListener('form.completed', function () {
							sendGoogleAnalytics(this, true);
						}, false);
						break;
					default:
						elements[i].onclick = function () {
							sendGoogleAnalytics(this);
						};
						break;
				}
			}
		} else {
			setTimeout(init, 500);
		}
	}

	var sendGoogleAnalytics = function (element, form = false) {
		var category = element.getAttribute('data-category');
		var action = element.getAttribute('data-action');
		var label = element.getAttribute('data-label');

		var el = element.querySelector('#ContactForm_Office');
		if (form && el) {
			var value = el.options[el.selectedIndex].text;

			label = label + ': ' + value;
		}

		var el = element.querySelector('#InterestForm_Product');
		if (form && el) {
			var value = el.value;

			label = label + ': ' + value;
		}

		if (window.ga && ga.create)
			ga('send', 'event', category, action, label);
	}

	init();
});