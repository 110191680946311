import { customEvent } from './utils';

window.tryRun("form", function () {
    let validateEmail = function (email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    let validatePhone = function (phone) {
        var re = /^[\+]?[(]?[0-9]{3,4}[)]?[-\s\.]?[0-9]{6,8}$/im;
        return re.test(String(phone).toLowerCase());
    };
    let getInputValue = function (element) {
        switch (element.nodeName) {
        case "INPUT":
            switch (element.type) {
            case "text":
            case "hidden":
                return element.value;
            case "checkbox":
                return element.checked ? element.value : "";
            }
            break;
        case "SELECT":
            return element.value;
        }
        return "";
    };

    let animateError = function (input, lastHeight = -1, lastTime = Date.now()) {
        window.requestAnimationFrame(function () {
            var time = Date.now();
            var delta = time - lastTime;

            var rect = input.error.getBoundingClientRect();
            var height = rect.bottom - rect.top;
            if (lastHeight == -1)
                lastHeight = height;
            var newHeight = 0;
            if (input.error.classList.contains("visible")) {
                if (height < lastHeight - 1) {
                    input.error.style.maxHeight = "unset";
                    return;
                }
                newHeight = height + (delta * 0.4);
            } else {
                if (lastHeight < 0) {
                    input.error.style.maxHeight = "";
                    return;
                }
                newHeight = height - (delta * 0.4);
            }
            input.error.style.maxHeight = newHeight + "px";
            
            animateError(input, newHeight, time);
        });
    }
    let showError = function (input, msg) {
        input.errorInner.innerText = msg;
        if (input.error.classList.contains("visible"))
            return;

        input.error.classList.add("visible");
        animateError(input);
    }
    let hideError = function (input) {
        if (!input.error.classList.contains("visible"))
            return;

        input.error.classList.remove("visible");
        animateError(input);
    }

    let validate = function (input, changes) {
        var valid = true;
        var tested = false;
        var msg = "";

        if (input.requiredMessage) {
            valid &= getInputValue(input.element) != "";
            tested = true;
            msg = input.requiredMessage;            
        }
        if (input.phoneMessage) {
            valid &= validatePhone(getInputValue(input.element));
            tested = true;
            msg = input.phoneMessage;            
        }
        if (input.emailMessage) {
            valid &= validateEmail(getInputValue(input.element));
            tested = true;
            msg = input.emailMessage;            
        }                

        if (valid) {
            if (tested)
                hideError(input);
        } else {
            if (!changes) {
                showError(input, msg);
            }
        }

        return valid;
    };

    let handleChange = function (input) {
        if (input.dataForElement && input.dataForElements.length > 0) {
            var values = [];
            for (var i = 0; i < input.dataForElements.length; i++) {
                var value = getInputValue(input.dataForElements[i]);
                if (value != "")
                    values.push(value);
            }
            input.dataForElement.value = values.join("\n");
            input.dataForElement.dispatchEvent(new Event ('change') );
        }

        if (input.error)
            validate(input, true);
    };

    let handleInput = function (input) {
        input.element.addEventListener("change", function () {
            handleChange(input);
        });
        input.element.addEventListener("keyup", function () {
            handleChange(input);
        });        
    };

    let handleForm = function (form) {
        var inputs = [];

        var elements = form.querySelectorAll("[data-types]");
        for (var i = 0, element; element = form[i++] ;) {
            var input = {
                element: element,
                dataForElement: null,
                dataForElements: [],
                emailMessage: element.hasAttribute("data-val-email") ? element.getAttribute("data-val-email") : null,
                phoneMessage: element.hasAttribute("data-val-phone") ? element.getAttribute("data-val-phone") : null,
                requiredMessage: element.hasAttribute("data-val-required") ? element.getAttribute("data-val-required") : null,
                error: null,
                errorInner: null
            };
            if (input.emailMessage || input.phoneMessage || input.requiredMessage) {
                input.error = form.querySelectorAll("[data-valmsg-for='" + element.name + "']")[0];
                input.errorInner = document.createElement("div");
                if (!input.error) {
                    input.error = document.createElement("div");
                    input.element.parentElement.insertBefore(input.error, input.element.nextSibling);
                }
				input.error.classList.add("error");
                input.error.appendChild(input.errorInner);
            }

            if (input.element.hasAttribute("data-source")) {
                input.dataForElement = document.getElementById(input.element.getAttribute("data-source"));
                if (input.dataForElement)
                    input.dataForElements = form.querySelectorAll("[data-source='" + input.dataForElement.id + "']");
            }            

            if (input.error || input.dataForElements.length > 0) {
                inputs.push(input);
                handleInput(input);
            }
        }

        form.addEventListener("submit", function (e) {
            e.preventDefault();

            var clientKey = form.getAttribute("data-captcha-key");
            var action = form.getAttribute("data-captcha-action");

            if (typeof grecaptcha !== 'undefined') {
                grecaptcha.ready(function () {
                    grecaptcha.execute(clientKey, { action: action }).then(function (token) {
                        form.querySelector('input[name$="GoogleCaptchaToken"]').value = token;
                    }).then(function () {
                        var valid = true;
                        for (var i = 0; i < inputs.length; i++)
                            valid &= validate(inputs[i], false);

                        if (!valid)
                            return;

                        var formData = new FormData(form);
                        var request = new XMLHttpRequest();
                        request.overrideMimeType("application/json");
                        request.open("POST", form.action);
                        //request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

                        request.onreadystatechange = function () {
                            if (this.readyState === XMLHttpRequest.DONE) {
                                var resultMessage = "";
                                var isError = false;
                                var jsonObj = JSON.parse(this.response);

                                isError = jsonObj.error;
                                resultMessage = jsonObj.message;

                                if (!isError) {
                                    customEvent.call(form, 'form', 'completed');

                                    if (jsonObj.url !== null)
                                        document.location = jsonObj.url;
                                }

                                if (resultMessage != "") {
                                    document.getElementById(form.getAttribute("data-result-id")).innerHTML = resultMessage;

                                    var hideDescription = form.hasAttribute("data-hide-description");

                                    if (hideDescription) {
                                        var descriptionField = form.parentElement.querySelector('.block-description');

                                        if (descriptionField) {
                                            descriptionField.innerHTML = '';
                                        }
                                    }
                                    form.scrollIntoView({ block: 'center' })
                                }
                            }
                        };

                        request.send(formData);
                    });
                });
            } else {
                var valid = true;
                for (var i = 0; i < inputs.length; i++)
                    valid &= validate(inputs[i], false);

                if (!valid)
                    return;

                var formData = new FormData(form);
                var request = new XMLHttpRequest();
                request.overrideMimeType("application/json");
                request.open("POST", form.action);
                //request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

                request.onreadystatechange = function () {
                    if (this.readyState === XMLHttpRequest.DONE) {
                        var resultMessage = "";
                        var isError = false;
                        var jsonObj = JSON.parse(this.response);

                        isError = jsonObj.error;
                        resultMessage = jsonObj.message;

                        if (!isError) {
                            customEvent.call(form, 'form', 'completed');

                            if (jsonObj.url !== null) 
                                document.location = jsonObj.url;  
                        }

                        if (resultMessage != "") {
                            document.getElementById(form.getAttribute("data-result-id")).innerHTML = resultMessage;

                            var hideDescription = form.hasAttribute("data-hide-description");

                            if (hideDescription) {
                                var descriptionField = form.parentElement.querySelector('.block-description');

                                if (descriptionField) {
                                    descriptionField.innerHTML = '';
                                }
                            }
                            form.scrollIntoView({ block: 'center' })
                        }
                    }
                };

                request.send(formData);
            }
        });
    };

    let forms = document.querySelectorAll("form[data-form]");

    for (var i = 0; i < forms.length; i++)
        handleForm(forms[i]);
});